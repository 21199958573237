<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Your account is not setup! 🔐</h2>
        <p class="mb-2">Please contact an admin to setup your account.</p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="homeRoute()" v-if="homeRoute() !== '/not-setup'">Back to Home</b-button>
        <b-button variant="primary" class="mb-1 btn-sm-block" @click="logout">Logout</b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BLink,
    BImg,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      downImg: "require('@/assets/images/pages/not-authorized.svg'),",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = " require('@/assets/images/pages/not-authorized-dark.svg');";
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    logout() {
      return useJwt.logout();
    },
    homeRoute() {
      const permissions = JSON.parse(localStorage.getItem('$perm'));
      return getHomeRouteForLoggedInUser(permissions || null);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
